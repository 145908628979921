/** @jsx jsx */
import { Link } from 'gatsby'
import { FC } from 'react'
import { Box, Text, jsx, Image, Link as LinkBase } from 'theme-ui'
interface WorkCardProps {
  post: any
}
export const WorkCard = ({ post }: WorkCardProps) => (
  <Box sx={{ flex: 1, minWidth: ['100%', '50%', '33%'], mb: 6, mt: 3, mr: 4 }}>
    <Box>
      <Image
        src={post.node.mainImage.asset.fixed.src}
        height={post.node.mainImage.asset.fixed.height}
        width={post.node.mainImage.asset.fixed.width}
      />
    </Box>
    <Box pt={3} style={{ position: 'relative' }}>
      <Text variant="blockTitle" sx={{ color: 'grays.8', fontSize: 0}}>Product Design</Text>
      <LinkBase variant="blockTitleLink" href={`/projects/${post.node.id}`}>
        {post.node.title}
      </LinkBase>
    </Box>
  </Box>
)
