/** @jsx jsx */

import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { FC } from 'react'
import { Box, jsx, Container, Text, Flex, Image, Link as LinkBase,  Avatar, Card } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Navigation from '../components/navig'
import { WorkCard } from '../components/workcard'

export interface ProfileProps {
  data: any
}

interface TeamItemProps {
  name?: string
  role?: string
  id?: string
  team?: string
  _rawBio?: any
  image?: any
}

// interface WorkCard {
//   post?: any
// }

// export const WorkCard = ({ post }: WorkCard) => {
//   return (
//     <Card sx={{ flex: 1, minWidth: ['100%', '50%', '24ch'], mb: 6, mt: 3, mr: 1 }}>
//       <Box>
//         <Image
//           src={post.node.mainImage.asset.fixed.src}
//           height={post.node.mainImage.asset.fixed.height}
//           width={post.node.mainImage.asset.fixed.width}
//         />
//       </Box>
//       <Box pt={3} style={{ position: 'relative' }}>
//         {/* variant="text.blockTitleCentre" */}
//         <Text variant="sectionTitle" sx={{ p: 0, m: 0, mb: 1, color: 'grays.3', fontSize: 2 , fontWeight: 'thin'}}>Product Designs</Text>
//         <Text as="h6" variant="blockTitleCentre" sx={{ fontSize: 0, color: 'grays.8'}}>{post.node.title}</Text>
//         {/* <Link as={LinkBase} variant="blockInfoCenter" to={`/projects/${post.node.id}`}>
//           {post.node.title} x
//         </Link> */}
//       </Box>
//     </Card>
//   )
// }

export const AboutSidebar = () => {
  return (
    <Box sx={{ pl: 0, pr: 5, minWidth: '20ch', pt: 6 }}>
      <Link to="/about/">
        <Text as="h2" sx={{ fontWeight: 700, borderBottom: 'solid 1px #eee', pb: 3, mb: 3 }} variant="personName">
          About
        </Text>
      </Link>
      <Link to="/about/team">
        <Text as="h2" sx={{ borderBottom: 'solid 1px #eee', pb: 3, mb: 3 }} variant="personName">
          Team
        </Text>
      </Link>
      <Link to="/about/brand">
        <Text as="h2" variant="personName">
          Brand
        </Text>
      </Link>
    </Box>
  )
}

const Profile: FC<ProfileProps> = ({ data }) => {
  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || []

  return (
    <Layout>
      <Navigation />
      <Box variant="box.plateBig" sx={{ px: 4, bg: 'base' }}>
        <Halo title="About" url="https://zeroswap.io/about" />
        <Container>
          {postEdges && (
            <Flex
              sx={{
                pt: 7,
                // listStyle: 'none',
                flexWrap: ['wrap', 'wrap'],
                flexDirection: ['column', 'row', 'row'],
                // display: 'grid',
                // gridGap: 3,
                // gridTemplateColumns: 'repeat(300px, minmax(300px, 1fr))',
              }}
            >
              {postEdges.map((post: any) => (
                <WorkCard post={post} />
              ))}
            </Flex>
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default Profile

export const pageQuery = graphql`
  query CaseStudiesPageQuery {
    caseStudies: allSanityCaseStudy(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fixed(width: 400, height: 200) {
                width
                height
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
